<template>
  <div>
        <div class="main" v-title data-title="百川资管-联系我们">
    </div>
    <Header class="header" active="contact"/>
    <div class="banner">
      <div class="background">
        <b-container>
          <div class="item">
            <div class="title">联系百川</div>
            <b-row>
              <div class="col-8">
                <div class="sub-title">电话：0755-23482434</div>
                <div class="sub-title">邮箱：bczg@baichuanasset.com</div>
                <div class="sub-title">北京办公室地址：北京市海淀区温泉镇温泉路84号临10号1257</div>
                <div class="sub-title">深圳办公室地址：深圳市福田区福华一路98号卓越大厦306</div>
              </div>
              <div class="col-4">
                <div>
                  <div class="qr-desc">微信扫码关注</div>
                  <img src="../assets/qrcode.png" class="qrcode">
                </div>
              </div>
            </b-row>

          </div>

        </b-container>
      </div>
    </div>
    <div class="content">
    </div>
    <Cfooter class="footer" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Cfooter from '@/components/Cfooter.vue'

export default {
  name: 'Join',
  components: {
    Header,
    Cfooter
  }
}
</script>

<style scoped>
.banner {
  height: 550px;
  margin-bottom: 84px;
}
.banner .background {
  width: 100%;
  height: 100%;
  color: #333333;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/contact.jpg");
}
.banner .item {
  height: 330px;
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  top: 150px;
  padding: 50px;
}
.banner .title {
  text-align: center;
  font-size: 24px;
  padding-bottom: 20px;
}
.banner .sub-title {
  line-height: 2;
}
.banner .col-4{
    padding-top: 50px;
}
.qr-desc{
  width: 132px;
  text-align: center;
  padding-bottom: 10px;
}
.qrcode{
  width: 129px;
}
</style>
