<template>
  <div>
    <div>Copyright &copy;{{year}} 百川财富（北京）投资管理有限公司</div>
    <p>
            <a  href="https://beian.miit.gov.cn" target="_blank" style="text-decoration:underline;color:#ffffff">京ICP备2021004903号-1</a>
          </p>
  </div>
</template>
<script>
export default {
  data () {
    return {
      year: ''
    }
  },
  methods: {
    getYear: function () {
      return new Date().getFullYear()
    }
  },
  mounted: function () {
    this.year = this.getYear()
  }
}
</script>

<style scoped>
.footer {
  color: #ffffff;
  background-color: #000000;
  padding: 30px 0 30px 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
